import React, { Component } from 'react';
import img from './img/geohome_logo.webp'
import img2 from './img/geohome_device.webp'
import "@fontsource/montserrat";

class Geohome extends Component {
    render() {
        return (
            <div id="geohome" class="Container m-5" className="text-center afterNavBar">
                <div Class="row mx-4">
                    <div class="col-md-12 col-sm-12 col-lg-6 text-center my-3">
                        <img
                            class="w-100"
                            src={img}
                            className="d-inline-block align-center"
                            alt="CE"
                        />
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-5 text-center">
                        <h2 > Tocator de resturi alimentare </h2>
                    </div>
                </div>

                <div Class="row align-items-center m-5 ">
                    <div class="col-md-12 col-sm-12 col-lg-6">
                        <img
                            class="img-fluid"
                            src={img2}
                            alt="CE"
                        />
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-5 px-1 py-3">
                        <p class='mtext'>
                            Un tocator de resturi alimentare este un aparat a cărui sarcină este de a
                            măcina și utiliza resturile de alimente. Acum puteţi elimina prin sistemul
                            de evacuare al chiuvetei, în mod sigur şi igienic, resturile de mâncare.
                            Nu mai trebuie să le lăsaţi în coşul de gunoi, unde pot produce mirosuri
                            neplăcute, atrag insecte şi se umplu de bacterii. </p>
                        <span >
                            <a href="https://geohome.md" class="btn btn-primary mlink" tabindex="-1" role="button"  >Mai multe detalii</a>
                        </span>

                    </div>
                </div>
            </div>
        );
    }
}

export default Geohome;