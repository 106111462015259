import React, { Component } from 'react';
import logo from './img/geotermal_logo.webp'

class Bottom extends Component {
    render() {
        return (

            <div Class="Container w-100 p-3 mbgb ">
                <div class="row me-auto text-center">
                    <div class="col-md-3 col-sm-12">
                        <img
                            src={logo}
                            height="70"
                            width="70"
                            className="d-inline-block text-center align-center"
                            alt="Logo"
                        />
                    </div>
                    <div class="col-md-5 col-sm-12  m-auto mtext text-center">
                        <h2>2024 SC "Geotermal-AV" SRL</h2>
                    </div>
                    <div class="col-md-4 col-sm-12 mlink text-center  ">
                        <h5><a href="tel:+37379939333">(+373) 799 39 333</a></h5>
                        <h5><a href="mailto:info@geotermal.md">info@geotermal.md</a></h5>
                    </div>
                </div>
            </div>
        );
    }
}

export default Bottom;