import React, { Component } from 'react';
import img from './img/meltem_logo.webp'
import img2 from './img/meltem_device.webp'
import "@fontsource/montserrat";

class Meltem extends Component {
    render() {
        return (
            <div id="meltem" class="Container m-5" className="text-center afterNavBar">
                <div Class="row mx-4">
                    <div class="col-md-12 col-sm-12 col-lg-6 text-center my-3">
                        <img
                            class="w-100"
                            src={img}
                            className="d-inline-block align-center"
                            alt="CE"
                        />
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-5 text-center">
                        <h2 > Ventilare cu recuperare de caldura MELTEM </h2>
                    </div>
                </div>

                <div Class="row align-items-center m-5 ">
                    <div class="col-md-12 col-sm-12 col-lg-6">
                        <img
                            class="img-fluid"
                            src={img2}
                            alt="CE"
                        />
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-5 px-1 py-3">
                        <p class='mtext'>
                            Meltem este un brand German, producator de sisteme de ventilație descentralizată
                            pentru locuințe, spații de birouri, institutii medicale etc.
                        </p>
                        <p class='mtext'>
                            Acest dispozitiv funcționează pentru bunăstarea și sănătatea dumneavoastră.
                            Aer proaspăt și filtrat, cu pierderi minime de căldură este asigurat automat
                            în locuință. Aerul viciat este evacuat, iar căldura este transferată (fără a
                            intra în contact) aerului curat introdus în încăpere.
                        </p>
                        <span >
                            <a href="https://meltem.md/" class="btn btn-primary mlink" tabindex="-1" role="button"  >Mai multe detalii</a>
                        </span>

                    </div>
                </div>
            </div>
        );
    }
}

export default Meltem;