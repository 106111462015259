import React, { Component } from 'react';
import img from './img/ochsner_logo.webp'
import img2 from './img/ochsner_device.webp'
import "@fontsource/montserrat";

class Ochsner extends Component {
    render() {
        return (
            <div id="ochsner" class="Container m-5" className="text-center afterNavBar">
                <div Class="row mx-4">
                    <div class="col-md-12 col-sm-12 col-lg-6 text-center my-3">
                        <img
                            class="w-100"
                            src={img}
                            className="d-inline-block align-center"
                            alt="CE"
                        />
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-5 text-center">
                        <h2 > Pompe de caldură OCHSNER </h2>
                    </div>
                </div>

                <div Class="row align-items-center m-5 ">
                    <div class="col-md-12 col-sm-12 col-lg-6">
                        <img
                            class="img-fluid"
                            src={img2}
                            alt="CE"
                        />
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-5 px-1 py-3">

                        <p class='mtext'> Pompele de căldură OCHSNER utilizează surse de energie care sunt
                            disponibile în cantități aproape nelimitate:
                            <ul>
                                <li>Căldură din aerul ambiant</li>
                                <li>Căldură de la sol</li>
                                <li>Căldură de la apele subterane</li>
                            </ul>
                            Într-un proces termodinamic, pompele de căldură „pompează” temperatura
                            sursei de căldură la un nivel mai înalt și o fac utilizabilă pentru
                            încălzire și apă caldă Pompe de căldură OCHSNER: calitate superioară
                            fabricate în Austria </p>
                        <span >
                            <a href="https://ochsner.md/" class="btn btn-primary mlink" tabindex="-1" role="button"  >Mai multe detalii</a>
                        </span>

                    </div>
                </div>
            </div>
        );
    }
}

export default Ochsner;