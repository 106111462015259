import React, { Component } from 'react';
import img from './img/popodusche_logo.webp'
import img2 from './img/popodusche_device.webp'
import "@fontsource/montserrat";

class Popodusche extends Component {
    render() {
        return (
            <div id="popodusche" class="Container m-5" className="text-center afterNavBar">
                <div Class="row mx-4">
                    <div class="col-md-12 col-sm-12 col-lg-6 text-center my-3">
                        <img
                            class="w-100"
                            src={img}
                            className="d-inline-block align-center"
                            alt="CE"
                        />
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-5 text-center">
                        <h2 > Bideu electric </h2>
                    </div>
                </div>

                <div Class="row align-items-center m-5 ">
                    <div class="col-md-12 col-sm-12 col-lg-6">
                        <img
                            class="img-fluid"
                            src={img2}
                            alt="CE"
                        />
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-5 px-1 py-3">
                        <p class='mtext'>
                        Bideul este un obiect sanitar conceput pentru igiena zonelor intime. 
                        Acesta este instalat în baie și este potrivit atât pentru femei, cât și 
                        pentru bărbați. Cel mai bun model de bideu este cel electric, pentru că 
                        are numeroase avantaje: se montează pe vasul de WC, se conecteaza la rețeaua
                         de apă, la cea de curent electric și poate fi controlat și activat printr-un 
                         panou de comandă. </p>
                        <span >
                            <a href="https://bideu.md/" class="btn btn-primary mlink" tabindex="-1" role="button"  >Mai multe detalii</a>
                        </span>

                    </div>
                </div>
            </div>
        );
    }
}

export default Popodusche;