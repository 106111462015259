import React, { Component } from 'react';
// import CarouselBox from '../Components/CarouselBox';
// import ContHome1 from '../Components/ContHome1';
import C1 from '../Components/C1';
import Ochsner from '../Components/Ochsner';
// import Test from '../Components/test';
import Meltem from '../Components/Meltem';
import Popodusche from '../Components/Popodusche';
import Geohome from '../Components/Geohome';
import Bottom from '../Components/Bottom';
import Top from '../Components/Top';

// import ContactForm from '../Components/ContactForm';

export default class Home extends Component {
    render() {
        return (
            <>
                <Top />
                <C1 />
                <hr class="mline" />
                <Meltem />

                <hr class="mline" />
                <Ochsner />
                <hr class="mline" />
                <Geohome />
                <hr class="mlineb" />
                <Bottom />
                {/* <ContactForm/> */}
                {/* <Test/>  */}
                {/* <Geohome/> */}
                {/* <CarouselBox /> */}
                {/* <ContHome1 /> */}
            </>
        );
    }
}
//                <hr class="mline" />
//                <Popodusche />
