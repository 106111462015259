import React, { Component } from 'react';
import img from './img/ce.webp'
import { Container } from 'react-bootstrap';

class C1 extends Component {
    render() {
        return (
            <Container id="f" className="text-center afterNavBar">
                <h1 className='m-5'>
                    Soluții pentru o casă energoeficientă
                </h1>
                <img
                    src={img}
                    class="img-fluid"
                    alt="CE"
                />
            </Container>

        );
    }
}

export default C1;